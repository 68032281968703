import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import videoUrl from '../videos/pagina.mp4'
import { useTranslation } from "react-i18next"

export default function Cover() {
  const { t, i18n } = useTranslation()

  return (
    <div className="jumbotron">
      <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src={videoUrl} type="video/mp4" />
          {t("general.not-supported")}
        </video>
      </div>
      <Container fluid>
        <div class="caption">
          <p className="lead">{t("cover.content")}</p>
          <p className="lead">
            <a className="btn btn-outline-light btn-lg br-none" href="#intro" role="button">{t("general.learn-more")}</a>
          </p>
        </div>
      </Container>
    </div>
  )
}