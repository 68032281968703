import Container from 'react-bootstrap/Container';
import img2 from '../images/image2.jpg';
import img3 from '../images/image3.jpg';
import img4 from '../images/image4.jpg';
import img8 from '../images/image8.jpg';
import img9 from '../images/image9.jpg';
import img10 from '../images/image10.jpg';
import { useTranslation } from "react-i18next"


export default function Services() {
  const { t, i18n } = useTranslation()

  return (
    <section className="features4 services" id="work">
      <Container>
        <div className="row">
          <div className="col-12">
            <div className="title-wrapper">
              <h3 className="mbr-section-title mbr-fonts-style display-5">
                {t("sections.work")}
              </h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center cards">
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img2} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-1-head")}</strong>
                </h4>
                <p>{t("services.item-1-content")}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img3} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-2-head")}</strong>
                </h4>
                <p>{t("services.item-2-content")}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img4} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-3-head")}</strong>
                </h4>
                <p>{t("services.item-3-content")}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img8} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-4-head")}</strong>
                </h4>
                <p>{t("services.item-4-content")}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img9} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-5-head")}</strong>
                </h4>
                <p>{t("services.item-5-content")}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 card">
            <div className="card-link">
              <div className="card-wrapper">
                <div className="card-overlay"></div>
                <img src={img10} alt=""/>
              </div>
              <div className="card-wrap">
                <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                <h4 className="card-title mbr-fonts-style display-5">
                  <strong>{t("services.item-6-head")}</strong>
                </h4>
                <p>{t("services.item-6-content")}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}