import Container from 'react-bootstrap/Container';
import img6 from "../images/image6.jpg";
import img5 from "../images/image5.jpg";
import { useTranslation } from "react-i18next"

export default function Intro() {
  const { t, i18n } = useTranslation()

  return (
    <section className="intro" id="intro"> 
      <Container fluid className="p-0">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6"></div>
          <div className="col-12 col-lg-6">
            <div className="text-wrapper">
              <p className="mbr-text mbr-fonts-style display-7">
                <strong>{t("intro.content")}
                </strong>
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="image-wrapper">
              <img className="img-absolute" src={img6} alt="" />
              <img src={img5} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}