import { Container } from "react-bootstrap"
import ukFlag from "../images/uk.svg"
import spainFlag from "../images/spain.svg"
import { useTranslation } from "react-i18next"
import ContactForm from "./ContactForm"

export default function Contact() {
  const { t, i18n } = useTranslation()

  return (
    <section className="form1 contact" id="connect">
      <Container>
        <div className="title-wrapper">
          <h2 className="mbr-section-title mbr-fonts-style display-5">
            <strong>{t("sections.contact")}</strong>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-8">
            <p className="contact-text display-7">
              {t("contact.content-1")} <a href="mailto:connect@cgnbiosciences.com">connect@cgnbiosciences.com</a> {t("contact.content-2")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 form-title">
            <div className="contact-block">
              <h3 className="mbr-section-subtitle mbr-fonts-style display-7">
                <strong>{t("contact.our-offices")}</strong>
              </h3>
              <div className="row">
                <div className="location-block col-12 col-md-6 col-lg-12">
                  <div className="location-icon"><img src={spainFlag} alt="" width={40} /></div>
                  <div className="location-info">
                    <strong className="d-block">{t("general.spain")}</strong>
                    <span className="d-block">Barcelona Health Hub</span>
                    <span className="d-block">C/de St. Antoni María Claret 167</span>
                    <span className="d-block">Horta-Guinardó, 08025</span>
                    <span className="d-block">Barcelona</span>
                  </div>
                </div>
                <div className="location-block col-12 col-md-6 col-lg-12">
                  <div className="location-icon"><img src={ukFlag} alt="" width={40} /></div>
                  <div className="location-info">
                    <strong className="d-block">UK</strong>
                    <span className="d-block">3/3 75 Queen Street</span>
                    <span className="d-block">G13BZ, Glasgow</span>
                    <span className="d-block">{t("general.scotland")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 form-wrapper">
            <ContactForm />
          </div>
        </div>
      </Container>
    </section>
  )
}