
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import emailjs from '@emailjs/browser'
import swal from 'sweetalert'

const initialValues = {
  fullName: '',
  email: '',
  phone: '',
  industry: '',
  message: ''
}

export default function ContactForm() {
  const { t, i18n } = useTranslation()
  const [values, setValues] = useState(initialValues)
  const [isDisabled, setIsDisabled] = useState(false)

  function handleChange(event) {
    const { name, value } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsDisabled(true);

    emailjs.sendForm('service_jge77kr', 'template_13dpwqe', event.target, '3-ECcnWt4n8nbjmBl')
      .then(response => {
        setValues(initialValues);
        setIsDisabled(false);
        swal(t("contact.submit-success-head"), t("contact.submit-success-subhead"), "success");
      })
      .catch(error => {
        swal(t("contact.submit-error-head"), t("contact.submit-error-subhead"), "error");
        setIsDisabled(false);
      });
  }

  return (
    <form onSubmit={handleSubmit} className="mbr-form form-with-styler">

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h3 className="mbr-section-subtitle mbr-fonts-style display-7">
            <strong>{t("contact.form-head")}</strong>
          </h3>
        </div>

        <div className="col-lg-12 col-md col-12 form-group mb-3 mb-3">
          <input
            required
            type="text"
            name="fullName"
            placeholder={`${t("contact.form-name")}*`}
            className="form-control display-7"
            value={values.fullName}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className="col-lg-12 col-md col-12 form-group mb-3">
          <input
            required
            type="email"
            name="email"
            placeholder={`${t("contact.form-email")}*`}
            className="form-control display-7"
            value={values.email}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
          <input
            type="tel"
            name="phone"
            placeholder={`${t("contact.form-phone")}`}
            className="form-control display-7"
            value={values.phone}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
          <input
            type="text"
            name="industry"
            placeholder={`${t("contact.form-industry")}`}
            className="form-control display-7"
            value={values.industry}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-for="textarea">
          <textarea
            name="message"
            placeholder={`${t("contact.form-message")}*`}
            className="form-control display-7"
            value={values.message}
            onChange={handleChange}
            disabled={isDisabled}
          ></textarea>
        </div>
        <div className="col-12 col-md-auto mbr-section-btn">
          <button type="submit" className="btn btn-primary-outline display-4 br-none" disabled={isDisabled}>
            {isDisabled ? t("general.sending") : t("general.submit")}
          </button>
        </div>
      </div>
    </form>
  )
}