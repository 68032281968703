import { Container } from "react-bootstrap";
import instagramIconUrl from "../images/instragram.svg"
import linkedinIconUrl from "../images/linkedin.svg"
import youtubeIconUrl from "../images/youtube.svg"
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t, i18n } = useTranslation()

  return (
    <footer className="footer">
      <Container>
        <div className="wrapper">
          <span>© 2024 Connexon Global Network — {t("footer.copyright")}</span>
          <span className="social">{t("footer.follow-us")}
            {/*<a href="#"><img src={instagramIconUrl} alt="Instagram" width="30" /></a>*/}
            <a href="https://www.youtube.com/@cgnbiosciences" target="_blank" rel="noreferrer"><img src={youtubeIconUrl} alt="YouTube" width="30" /></a>
            <a href="https://www.linkedin.com/company/cgn-life-sciences/" target="_blank" rel="noreferrer"><img src={linkedinIconUrl} alt="LinkedIn" width="30" /></a>
          </span>
        </div>
      </Container>
    </footer>
  )
}