import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../images/Logo.svg"
import ukFlag from "../images/uk.svg"
import spainFlag from "../images/spain.svg"
import { useTranslation } from "react-i18next";

function Navigation() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang)
    setIsOpen(false)
  }

  return (
    <Navbar data-bs-theme="light" className="navbar_main">
      <Container fluid>
        <Navbar.Brand href="/" className="logo">
          <img src={Logo} alt="CGN biosciences" />
        </Navbar.Brand>
        <div className={`menu ${isOpen ? "active" : ""}`}>
          <Nav className="ms-auto menu-list">
            <Nav.Link href="#about" onClick={() => setIsOpen(false)}>{t("sections.about")}</Nav.Link>
            <Nav.Link href="#work" onClick={() => setIsOpen(false)}>{t("sections.work")}</Nav.Link>
            <Nav.Link href="#connect" onClick={() => setIsOpen(false)}>{t("sections.contact")}</Nav.Link>
          </Nav>
          <Nav className="lang-selector">
            <button onClick={() => handleChangeLang("en")}><img src={ukFlag} alt={t("lang.en")} width={36} /></button>
            <button onClick={() => handleChangeLang("es")}><img src={spainFlag} alt={t("lang.es")} width={36} /></button>
          </Nav>
        </div>
        <button onClick={handleToggleOpen} className={`hamburger ${isOpen ? "active" : ""}`}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </Container>
    </Navbar>
  );
}

export default Navigation;