import logo from './logo.svg';
import Navigation from './components/Navigation';
import Cover from './components/Cover';
import Intro from './components/Intro';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import CommercialBanner from './components/CommercialBanner';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Cover />
      <Intro />
      <About />
      <Services />
      <CommercialBanner />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
