import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img19 from '../images/image19.jpg';
import { useTranslation } from "react-i18next";
import logo1 from "../images/absw_member_2024_banner_black_large.png";
import logo2 from "../images/Member+2024.png";
import logo3 from "../images/hpc_reg-logo_cmyk.png";
import profile1 from "../images/cgn_profile.png";
import linkedinIconColor from "../images/linkedin_color.svg";

export default function About() {
  const { t, i18n } = useTranslation();

  return (
    <section className="about" id="about">
      <Container fluid>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-container">
                <h3 className="mbr-section-title mbr-fonts-style display-5">
                  {t("sections.about")}
                </h3>
                <p className="mbr-text mbr-fonts-style display-7">
                {t("about.content")}
                </p>
              </div><img src={img19} className="img-cover" alt=""/>
            </div>
          </div>
        </div>
      </Container>
      <Container className="profile-container">
        <div className="profile-block">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile-photo-col">
                <img src={profile1} alt="" className="profile-photo" />
              </div>
            </div>

            <div className="col-lg-8">
              <div className="profile-info-col">
                <h4 className="profile-title">{t("about.profile.full-name")}</h4>
                <h5 className="profile-subtitle">{t("about.profile.position")}</h5>
                <h6 className="profile-job">{t("about.profile.profession")}</h6>
                <p className="profile-description">{t("about.profile.description")}</p>
                <a href="https://www.linkedin.com/in/carlos-germ%C3%A1n-nocera-aa3608175/" className="profile-link" target="_blank" rel="noreferrer">
                  <img src={linkedinIconColor} alt="linkedin" width={30} /><span>{t("about.profile.linkedin-link")}</span>
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </Container>
      <Container className="supported-container">
        <div className="supported-wrapper">
          <h3>{t("about.support")}</h3>
          <div className="logos">
            <img className="img-br-1" src={logo1} alt="absw member" />
            <img className="img-br-2" src={logo2} alt="TOPRA member" />
            <img className="img-br-3" src={logo3} alt="hcpc registered" />
          </div>
        </div>
      </Container>
    </section>
  )
}