import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import icon from "../images/lab-test-tube.svg";

export default function CommercialBanner() {
  const { t, i18n } = useTranslation();

  return (
    <section className="commercial-banner">
      <Container>
        <div className="banner-wrapper">
          <div className="icon"><img src={icon} alt="icon" width="100%" /></div>
          <a href="#connect" className="text">{t("commercial.message-1")}</a>
        </div>
      </Container>
    </section>
  )
}